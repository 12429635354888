// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import axios from 'axios';

axios.defaults.withCredentials = true;

// Set API URL based on environment
const apiUrl =  window.location.href.includes('staging') ? "https://api-staging.myfbcapp.com/" : window.location.href.includes('myfbcapp') ? "https://api.myfbcapp.com/" : "http://localhost:8080/";
// const apiUrl = "http://192.168.1.77:8080/"
// const apiUrl = "http://localhost:8080/"

export default {

    apiUrl, // Return active API URL

    // Get request
    get: async (endpoint, data = {}, noAuth = false, responseType = "") => {

        let res = await axios.get(apiUrl + endpoint, { responseType, params: data});

        // let res = await axios.get(apiUrl + "api/" + endpoint, data);

        // // Authorization error - redirect to login page
        // if (!res.data.auth && !noAuth)
        //     return window.location = '/login';

        if (res.data && res.data.error)
            console.log(res.data.error);

        return res.data;

    },

    // Post request
    post: async (endpoint, data = {}, noAuth = false, config = {}) => {
        let res = await axios.post(apiUrl + endpoint, data, config);

        // // Authorization error - redirect to login page
        // if (!res.data.auth && !noAuth)
        //     return window.location = '/login';

        if (res.data && res.data.error)
            console.log(res.data.error);

        return res.data;

    }

}