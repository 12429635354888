// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// import HttpsRedirect from 'react-https-redirect';

// Components
import Template from './components/Template';

// Pages
import Login from './pages/Login';
import Home from './pages/Home';
import NewClaim from './pages/NewClaim';
import ClientPlan from './pages/ClientPlan';
import DocCapture from './pages/DocCapture';
import FinishSignature from './pages/FinishSignature';
import ClaimComplete from './pages/ClaimComplete';
import SignUp from './pages/SignUp';
import Claim from './pages/Claim';
import VerifyEmail from './pages/VerifyEmail';
import Welcome from './pages/Welcome';
import AccountSettings from './pages/AccountSettings';
import ForgotPage from './pages/ForgotPage';
import Support from './pages/Support';
import ChangePassword from "./pages/ChangePassword";
import LoadingOverlay from './components/LoadingOverlay';
import AddAppInstructions from './pages/AddAppInstructions';


// Styles
import './style.css';

import api from './api';

import crypto from "crypto"
import delay from "delay"

import {getPhrasesByPageAndLanguage} from './constants/languagePhrases';


const isMobile = () => {
    return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
}

const langKey = 'app';

export default class App extends React.Component {

    // Local state
    state = {
        loadingOverlay: false,
        user: null,
        loading: false,

        navOpen: false,

        newClaimData: { },

        showAddAppHelp: false,

        language: 'english',
        phrases: {},

    }

    containerRef = React.createRef();

    componentDidMount = async () => {

        this.getPhrases();

        const userRefreshFunction = async () => {

            if (this.state.user)
                this.tryLogin();

            await delay(60 * 1000);
            userRefreshFunction();
        }

        userRefreshFunction()

    }

    getPhrases = () => {
        this.setState({phrases: getPhrasesByPageAndLanguage(langKey, this.state.language)});
    }

    updateNewClaimData = updates => {
        this.setState({newClaimData : updates})
    }

    setUser = newUserData => {
        this.setState({user: newUserData})
    }

    tryLogin = async () => {

        let returnValue = false

        let user = await api.get("self/get").catch(() => {
            returnValue = true
        }).catch(() => {})

        if (returnValue === false)
        {
            this.setState({user})
        }

        return returnValue

    }

    // Create verification for user
    createVerification = async () => {

        crypto.randomBytes(64, async (err, buffer) => {

            let key = buffer.toString("hex")

            console.log(err)
            console.log(key)

            await api.post("self/update", { diff: { verificationKey: key, verificationDate: null } })
            await api.post("email/sendVerification", {
                user: this.state.user._id,
                key
            })

            this.setLoadingState(false);
        })

    }

    // Loading controller
    loading = async () => await this.setState({ loading: true });
    loaded = () => setTimeout(() => this.setState({ loading: false }), 250);

    login = user => {
        this.setState({user})
    }

    logout = () => {
        api.post("self/logout")
        this.setState({ user: null })
    }

    closeMenu = () => {
        this.setState({ navOpen: false });
    }

    // Add new claim image
    onClaimImageCapture = async (imgData) => {

        // TODO @Marcel: Store the image
        const newClaimData = this.state.newClaimData;

        if (!newClaimData.images)
            newClaimData.images = [];

        newClaimData.images.push(imgData);

        await this.setState({ newClaimData });

    }

    // Delete claim img
    deleteClaimImage = async (imageIdx) => {

        if (!this.state.newClaimData.images)
            return;

        let images = this.state.newClaimData.images;
        images.splice(imageIdx, 1);

        await this.setState(prevState => ({
            newClaimData: {
                ...prevState.newClaimData,
                images: images
            }
        }));

    }

    // Update the claim signature
    setClaimSignature = async (base64Signature) => {

        await this.setState(prevState => ({
            newClaimData: {
                ...prevState.newClaimData,
                signature: base64Signature
            }
        }));

    }

    loadingTimeout = null;

    setLoadingState = state => {

        this.setState({ loadingOverlay: state })
        if (this.loadingTimeout)
            clearTimeout(this.loadingTimeout)

        // If setting the state to true, nothing should be loading for longer than 10 seconds. If that does happen, snap out of the loading screen and return to the home screen.
        if (state === true) {
            this.loadingTimeout = setTimeout(async () => {
                alert("There was a network error. We're sorry, please try again.")
                window.location = "/"
                this.setState({ loadingOverlay: false})
                this.loadingTimeout = null
            }, 20000)
        }
    }

    // Render function
    render = () => {

        const mobile = isMobile();

        return (<>
            {/* <HttpsRedirect> */}
                <Router onClick={() => this.closeMenu()}>
                    <div className='appComponent'>

                        {/* <div className={mobile ? 'appContainer_left' : "appContainer_leftPC"} /> */}

                        {!mobile && <div className='appContainer_addApp'>
                            {<div className="addToPhone" onClick = {() => {
                                this.setState({showAddAppHelp: !this.state.showAddAppHelp})
                            }}>
                                <strong>{this.state.phrases.msgAppOnPhone1}</strong><br/><br/>
                                <strong>{this.state.phrases.msgAppOnPhone2}</strong><br/><br/>
                                <strong>{this.state.phrases.msgAppOnPhone3}</strong>
                            </div>}
                        </div>}

                        <div className={mobile ? 'appContainer': "appContainerPC"} ref={this.containerRef}>

                            {this.state.showAddAppHelp ? <div>

                                <AddAppInstructions language={this.state.language} />

                                <button
                                    style = {{
                                        position: "fixed",
                                        width: "250px",
                                        bottom: "2%",
                                        left: "calc(50% - 125px)"
                                    }}
                                    onClick={() => this.setState({showAddAppHelp: false})}
                                >{this.state.phrases.btnBack}
                                </button>

                            </div> : <div>

                                <Template user={this.state.user} logout = {this.logout} tryLogin={this.tryLogin}
                                    closeMenu={this.closeMenu} language={this.state.language}
                                    setLanguage={async language => {
                                        await this.setState({language});
                                        this.getPhrases();
                                    }}
                                />

                                {this.state.loadingOverlay && <LoadingOverlay />}

                                {/* Page controller (switch) */}
                                <Switch>

                                    {/* Home */}
                                    <Route
                                        path="/" exact
                                        render={props => <Home
                                            {...props}
                                            user={this.state.user}
                                            employeeData={this.state.employeeData}
                                            tryLogin={this.tryLogin}
                                            setLoadingState={this.setLoadingState}
                                            language={this.state.language}
                                        />}
                                    />

                                    {/* Login */}
                                    <Route
                                        path="/login" exact
                                        render={props => <Login
                                            {...props}
                                            setUser={this.setUser}
                                            onLogin={this.login}
                                            logout={this.logout}
                                            tryLogin={this.tryLogin}
                                            language={this.state.language}
                                        />}
                                    />

                                    {/* ForgotPage */}
                                    <Route
                                        path="/forgot" exact
                                        render={props => <ForgotPage
                                            {...props}
                                            onLogin={user => this.setState({ user })}
                                            onLogout={() => this.setState({ user: { _id: null } })}
                                            tryLogin={this.tryLogin}
                                            createVerification={this.createVerification}
                                            setLoadingState={this.setLoadingState}
                                            language={this.state.language}
                                        />}
                                    />

                                    {/* Sign Up */}
                                    <Route
                                        path="/signup" exact
                                        render={props => <SignUp
                                            {...props}
                                            onLogin={user => this.setState({ user })}
                                            tryLogin={this.tryLogin}
                                            createVerification={this.createVerification}
                                            setLoadingState={this.setLoadingState}
                                            language={this.state.language}
                                        />}
                                    />

                                    {/* Verify Email */}
                                    <Route
                                        path="/verify-email" exact
                                        render={props => <VerifyEmail
                                            {...props}
                                            user={this.state.user}
                                            onLogin={user => this.setState({ user })}
                                            onLogout={() => this.setState({ user: { _id: null } })}
                                            tryLogin={this.tryLogin}
                                            createVerification={this.createVerification}
                                            setLoadingState={this.setLoadingState}
                                            language={this.state.language}
                                        />}
                                    />

                                    {/* Support */}
                                    <Route
                                        path="/support" exact
                                        render={props => <Support
                                            {...props}
                                            onLogin={user => this.setState({ user })}
                                            onLogout={() => this.setState({ user: { _id: null } })}
                                            tryLogin={this.tryLogin}
                                            createVerification={this.createVerification}
                                            user={this.state.user}
                                            setLoadingState={this.setLoadingState}
                                            language={this.state.language}
                                        />}
                                    />

                                    {/* Account Settings */}
                                    <Route
                                        path="/account-settings" exact
                                        render={props => <AccountSettings
                                            {...props}
                                            onLogin={user => this.setState({ user })}
                                            onLogout={() => this.setState({ user: { _id: null } })}
                                            tryLogin={this.tryLogin}
                                            user={this.state.user}
                                            setLoadingState={this.setLoadingState}
                                            language={this.state.language}
                                        />}
                                    />

                                    {/* Change Password */}
                                    <Route
                                        path="/change-password" exact
                                        render={props => <ChangePassword
                                            {...props}
                                            tryLogin={this.tryLogin}
                                            user={this.state.user}
                                            setLoadingState={this.setLoadingState}
                                            language={this.state.language}
                                        />}
                                    />

                                    {/* Welcome to FBC */}
                                    <Route
                                        path="/welcome" exact
                                        render={props => <Welcome
                                            {...props}
                                            onLogin={user => this.setState({ user })}
                                            onLogout={() => this.setState({ user: { _id: null } })}
                                            tryLogin={this.tryLogin}
                                            setLoadingState={this.setLoadingState}
                                            language={this.state.language}
                                        />}
                                    />

                                    {/* Claims */}
                                    <Route
                                        path="/claims" exact
                                        render={props => <Claim
                                            {...props}
                                            user={this.state.user}
                                            claims={this.state.employeeData ? this.state.employeeData.claims : []}
                                            tryLogin={this.tryLogin}
                                            setLoadingState={this.setLoadingState}
                                            language={this.state.language}
                                        />}
                                    />

                                    {/* New Claim */}
                                    <Route
                                        path="/new-claim" exact
                                        render={props => <NewClaim
                                            {...props}
                                            user={this.state.user}
                                            tryLogin={this.tryLogin}
                                            newClaimData={this.state.newClaimData}
                                            updateNewClaimData={this.updateNewClaimData}
                                            setLoadingState={this.setLoadingState}
                                            language={this.state.language}
                                        />}
                                    />

                                    {/* Plan */}
                                    <Route
                                        path="/plan" exact
                                        render={props => <ClientPlan
                                            {...props}
                                            user={this.state.user}
                                            tryLogin={this.tryLogin}
                                            newClaimData={this.state.newClaimData}
                                            setLoadingState={this.setLoadingState}
                                            language={this.state.language}
                                        />}
                                    />

                                    {/* Document Capture by Camera */}
                                    <Route
                                        path="/document-capture" exact
                                        render={props => <DocCapture
                                            {...props}
                                            user={this.state.user}
                                            tryLogin={this.tryLogin}
                                            newClaimData={this.state.newClaimData}
                                            updateNewClaimData={this.updateNewClaimData}
                                            onPhotoCapture = {this.onClaimImageCapture}
                                            deleteClaimImage={this.deleteClaimImage}
                                            setLoadingState={this.setLoadingState}
                                            loadingOverlay={this.state.loadingOverlay}
                                            containerRef={this.containerRef}
                                            language={this.state.language}
                                        />}
                                    />

                                    {/* Claim Identify Who and E-sign */}
                                    <Route
                                        path="/finishup-signature" exact
                                        render={props => <FinishSignature
                                            {...props}
                                            user={this.state.user}
                                            tryLogin={this.tryLogin}
                                            newClaimData={this.state.newClaimData}
                                            updateNewClaimData={this.updateNewClaimData}
                                            setSignature={this.setClaimSignature}
                                            load={this.loading}
                                            done={this.loaded}
                                            setLoadingState={this.setLoadingState}
                                            language={this.state.language}
                                        />}
                                    />

                                    {/* Claim Submission Complete Whats next */}
                                    <Route
                                        path="/claim-submitted" exact
                                        render={props => <ClaimComplete
                                            {...props}
                                            tryLogin={this.tryLogin}
                                            user={this.state.user}
                                            setLoadingState={this.setLoadingState}
                                            language={this.state.language}
                                        />}
                                    />

                                </Switch>
                            </div>}
                        </div>
                    </div>
                </Router>
            {/* </HttpsRedirect> */}
        </>);
    };

};