// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React from 'react';
import Select from 'react-select';

import api from "../api";
import PlanCodePopup from '../components/PlanCodePopUp';

import { getPhrasesByPageAndLanguage } from '../constants/languagePhrases';
const langKey = 'newClaim';

export default class NewClaim extends React.Component {

    state = {
        code: "",
        valid: true,
        planCodePopUp: false,

        planExists: false,
        addingPlan: false,
        removingPlan: false,

        selectedPlan: '',
        plans: [],
        planToRemove: -1,

        phrases: {},
    }

    componentDidMount = async () => {
        this.props.setLoadingState(true);
        this.props.updateNewClaimData({ })

        if (!this.props.user){
            if (await this.props.tryLogin()) {
                this.props.history.push("/login")
                return null
            }
        }

        if (this.props.user.flagged)
        this.props.history.push("/")

        await this.fetchPlans();
        window.scrollTo(0, 0);
        this.props.setLoadingState(false);

        this.setState({phrases: getPhrasesByPageAndLanguage(langKey, this.props.language)});
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.language !== prevProps.language)
            this.setState({phrases: getPhrasesByPageAndLanguage(langKey, this.props.language)});
    }

    fetchPlans = async () => {
        let plans = [];
        for (let i = 0; i < this.props.user.plans.length; i++) {
            let plan = await api.get("plan/get", { id: this.props.user.plans[i] })
            plans.push(plan)
        }
        await this.setState({plans})
    }

    validateCode = async () => {
        if (this.state.plans.map(p => p.code.toUpperCase()).includes(this.state.code.toUpperCase())) {
            this.setState({ planExists: true });
            return;
        }

        this.props.setLoadingState(true);
        this.setState({ addingPlan: true });


        // Find the code in the db
        await api.get("plan/getByCode", { code: this.state.code.toUpperCase() })
            .then(async plan => {

                await api.post("self/update", { diff: { employer: plan.employer, $push: { plans: plan._id } } })
                await this.props.tryLogin();
                this.fetchPlans();

                this.props.setLoadingState(false);
                this.setState({ planExists: true, addingPlan: false, selectedPlan: plan._id });
            })
            .catch(() => {
                this.props.setLoadingState(false);
                this.setState({
                    code: "",
                    valid: false,
                    planExists: false,
                });
            })
    }

    closePopup = () => {
        this.setState({planCodePopUp: false})
    }

    getPlanOptions = () => {
        if (!this.state.plans || this.state.plans.length === 0)
            return [];

        const getLabel = plan => {
            return (
                <div>
                    <div>{plan.name}</div>
                    <div>{plan.year + ' (' + this.state.phrases.wordCode + ': ' + plan.code + ')'}</div>
                </div>
            )
        }

        return this.state.plans.map(p => {return {label: getLabel(p), value: p._id}})
    }

    removePlan = async (idx) => {
        const id = this.state.plans[idx]._id;
        const code = this.state.plans[idx].code;

        if (window.confirm(this.state.phrases.confirmDelete + code + '\"?')) {

            await api.post('self/removePlan', {
                id: this.props.user._id,
                plan: id,
            });

            await this.props.tryLogin();
            await this.fetchPlans();
        }
        console.log('plans', this.state.plans)
        this.setState({planExists: false, planToRemove: -1, removingPlan: this.state.plans.length === 0 ? false : this.state.removingPlan})
    }

    render = () => {
        console.log('state', this.state)
        console.log('props', this.props)

        if (!this.props.user)
            return null

        return (
            <div className="container">

                {/* select plan */}
                {this.state.removingPlan || this.props.user.email == 'user@test.com' ? <div>

                    <div className='newClaim_removePlanList'>
                        {this.state.plans.map((plan, planIdx) => {

                            let planStyle = {}
                            if (planIdx === this.state.planToRemove) {
                                planStyle.backgroundColor = 'rgb(0,117,175)';
                                planStyle.color = 'white';
                            } else
                                planStyle.backgroundColor = planIdx % 2 === 0 ? 'white' : 'rgba(0,0,0,0.05)';

                            return (
                                <div
                                    className='newClaim_removePlanItem'
                                    style={planStyle}
                                    onClick={async () => this.setState({planToRemove: planIdx})}
                                >
                                    <div>{plan.name}</div>
                                    <div>{plan.year + ' (' + this.state.phrases.wordCode + ': ' + plan.code + ')'}</div>
                                </div>
                            )
                        })}
                    </div>

                    <button className="next-page"
                        onClick={() => this.setState({ removingPlan: false, planToRemove: -1, planExists: false, code: '' })}
                        style={{ width: '40%', marginLeft: '5%' }}
                    >{this.state.phrases.btnBack}
                    </button>

                    {this.state.planToRemove !== -1 ? <button
                        onClick={() => this.removePlan(this.state.planToRemove)}
                        style={{ width: '40%', marginLeft: '10%' }}
                    >{this.state.phrases.btnDelete}
                    </button> : ""}

                </div>

                : this.props.user.plans && this.props.user.plans.length > 0 && !this.state.addingPlan ? <div>

                    <h1>{this.state.phrases.msgGetStarted}</h1>

                    <h3>{this.state.phrases.msgSelectPlan}</h3>

                    <Select
                        placeholder={this.state.phrases.dropClickToSelect}
                        key={'planSelect'}
                        className='planSelect_container'
                        classNamePrefix='planSelect'
                        value={this.state.selectedPlan ? this.getPlanOptions().filter(opt => opt.value === this.state.selectedPlan) : null}
                        options={this.getPlanOptions()}
                        onChange={async newPlan => this.setState({selectedPlan: newPlan.value})}
                    />

                    <div
                        className="change-plan-btn"
                        onClick={async () => this.setState({ addingPlan: true, planExists: false, code: '' })}
                        style={{ display: 'inline-block', left: '35px', position: 'absolute' }}
                    >
                        {this.state.phrases.btnAddPlan}
                        <img style={{ position: 'relative', left: '7px', height: '15px', width: '15px', }} src="add.svg" alt="add plan icon" />
                    </div>

                    <div
                        className="change-plan-btn"
                        onClick={async () => this.setState({ removingPlan: true })}
                        style={{ display: 'inline-block', right: '35px', position: 'absolute' }}
                    >
                        <img style={{ position: 'relative', right: '7px', height: '15px', width: '15px', }} src="remove-plan.svg" alt="remove plan icon" />
                        {this.state.phrases.btnRemovePlan}
                    </div>

                    {this.state.selectedPlan && <button
                        // ref='submitButton'
                        onClick={() => {
                            let data = {...this.props.newClaimData};
                            data.plan = this.state.plans.filter(p => p._id === this.state.selectedPlan)[0];
                            this.props.updateNewClaimData(data);
                            this.props.history.push('/plan');
                        }}
                        style={{
                            width: '80%',
                            marginLeft: '10%',
                            position: 'relative',
                            top: '40px',
                        }}
                    >{this.state.phrases.btnContinue}</button>}

                </div>

                : (!this.props.user.plans || this.props.user.plans.length === 0 || this.state.addingPlan) && <div>

                    <h1>{this.state.phrases.msgAddPlan}</h1>

                    <h3>{this.state.phrases.msgConnect ? this.state.phrases.msgConnect(this.state.addingPlan)[this.props.language] : ''}</h3>

                    <p>{this.state.phrases.msgInstructions1}<strong>{this.state.phrases.msgInstructions2}</strong>{this.state.phrases.msgInstructions3}</p>

                    <div className="hint" onClick={() => {this.setState({planCodePopUp: !this.state.planCodePopUp})}}>
                        {this.state.phrases.btnFindPlan}
                    </div>

                    {this.state.planCodePopUp ? <PlanCodePopup close={this.closePopup} language={this.props.language}></PlanCodePopup> : null}

                    {!this.state.planExists && <div>
                        <label>{this.state.phrases.lblPlanCode}</label>
                        <input
                            type="text"
                            value={this.state.code}
                            onChange={(e) => this.setState({ code: e.target.value.toUpperCase(), valid: true })}
                            onKeyPress={e => {
                                if (e.key === 'Enter' && this.state.code.length === 6)
                                    this.refs.submitButton.click();
                            }}
                        />
                    </div>}

                    {this.state.code.length === 6 && !this.state.planExists ?
                        // Code entered - ready to submit
                        <button
                            ref='submitButton'
                            onClick={() => this.validateCode()}
                            onSubmit={() => this.validateCode()}
                            style={{
                                width: '80%',
                                marginLeft: '10%'
                            }}
                        >{this.state.phrases.btnValidate}</button>

                    : this.state.valid && !this.state.planExists ?

                        // Code error
                        <div className="error">
                            {this.state.phrases.errEnterCode}
                            <button
                                    onClick={() => {
                                        if (!this.state.plans || this.state.plans.length === 0) { this.props.history.push("/") }
                                        else { this.setState({ addingPlan: false, code: '', planExists: false }) }
                                    }}
                                    style={{ width: '80%'}}
                                >{this.state.phrases.btnBack}
                            </button>
                        </div>

                    : (this.state.planExists ?

                        <div>
                            <div className="success">
                                {this.state.phrases.msgValidPlanCode}<br />
                                {this.state.phrases.msgPlanAdded}
                            </div>
                            <button
                                onClick={() => this.setState({addingPlan: false})}
                                style={{ width: '80%', marginLeft: '10%' }}
                            >{this.state.phrases.btnBack}
                            </button>
                        </div>
                    :
                        // Code error
                        <div className="error">
                            {this.state.phrases.errInvalidCode1}<br />
                            {this.state.phrases.errInvalidCode2}
                            <button
                                onClick={() => this.setState({ addingPlan: false })}
                                style={{ width: '80%'}}
                            >{this.state.phrases.btnBack}
                            </button>
                        </div>
                    )}

                </div>}
            </div>
        );
    }
};